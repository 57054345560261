import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import mattressIpsum from 'mattress-ipsum';
import { Tooltip, Button, Select } from '../../src';
import QuestionMark from '../../src/Icons/QuestionMark';
import { Row } from './helpers';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "tooltip"
    }}>{`Tooltip`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Tooltip from 'mfcl/Tooltip'
`}</code></pre>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`Tooltips help clarify what certain features do.`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Tooltip} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Hover only tooltips are not WCAG 2.0 / ADA compliant.`}</p>
    <Playground __position={1} __code={'<Row>\n  <Tooltip trigger={<Button>Click Me</Button>} position=\"bottom\">\n    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod\n    tempor incididunt ut labore et dolore magna.\n  </Tooltip>\n  <Tooltip trigger={<Button size=\"sm\">Click Me</Button>} position=\"top\">\n    {mattressIpsum(1, 50)}\n  </Tooltip>\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      mattressIpsum,
      Tooltip,
      Button,
      Select,
      QuestionMark,
      Row,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <Tooltip trigger={<Button mdxType="Button">Click Me</Button>} position="bottom" mdxType="Tooltip">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
      magna.
    </Tooltip>
    <Tooltip trigger={<Button size="sm" mdxType="Button">Click Me</Button>} position="top" mdxType="Tooltip">
      {mattressIpsum(1, 50)}
    </Tooltip>
  </Row>
    </Playground>
    <h2 {...{
      "id": "no-arrow"
    }}>{`No Arrow`}</h2>
    <Playground __position={2} __code={'<Row>\n  <Tooltip\n    arrow={false}\n    trigger={<Button>Click Me</Button>}\n    position=\"bottom\"\n  >\n    Where did my arrow go?\n  </Tooltip>\n  <Tooltip arrow={false} trigger={<Button>Click Me</Button>} position=\"top\">\n    Definitely no arrow\n  </Tooltip>\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      mattressIpsum,
      Tooltip,
      Button,
      Select,
      QuestionMark,
      Row,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <Tooltip arrow={false} trigger={<Button mdxType="Button">Click Me</Button>} position="bottom" mdxType="Tooltip">
      Where did my arrow go?
    </Tooltip>
    <Tooltip arrow={false} trigger={<Button mdxType="Button">Click Me</Button>} position="top" mdxType="Tooltip">
      Definitely no arrow
    </Tooltip>
  </Row>
    </Playground>
    <h2 {...{
      "id": "header"
    }}>{`Header`}</h2>
    <Playground __position={3} __code={'<Row>\n  <Tooltip\n    header=\"header\"\n    trigger={<Button>Click Me</Button>}\n    position=\"bottom\"\n  >\n    <p>I have a header</p>\n  </Tooltip>\n  <Tooltip\n    header=\"header\"\n    closeBtn\n    arrow={false}\n    trigger={<Button>Click Me</Button>}\n    position=\"top\"\n  >\n    <p>And I have a close button in my header</p>\n  </Tooltip>\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      mattressIpsum,
      Tooltip,
      Button,
      Select,
      QuestionMark,
      Row,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <Tooltip header="header" trigger={<Button mdxType="Button">Click Me</Button>} position="bottom" mdxType="Tooltip">
      <p>I have a header</p>
    </Tooltip>
    <Tooltip header="header" closeBtn arrow={false} trigger={<Button mdxType="Button">Click Me</Button>} position="top" mdxType="Tooltip">
      <p>And I have a close button in my header</p>
    </Tooltip>
  </Row>
    </Playground>
    <h2 {...{
      "id": "positions"
    }}>{`Positions`}</h2>
    <Playground __position={4} __code={'() => {\n  const positions = [\n    \'right\',\n    \'left\',\n    \'top\',\n    \'top-left\',\n    \'top-right\',\n    \'bottom-right\',\n    \'bottom\',\n    \'bottom-left\',\n  ]\n  return (\n    <Row>\n      {positions.map(position => (\n        <Tooltip\n          key={position}\n          position={position}\n          trigger={<Button size=\"sm\">{position}</Button>}\n        >\n          {position.toUpperCase()} Lorem ipsum dolor sit amet, consectetur\n          adipiscing elit, sed do eiusmod tempor incididunt ut labore et\n          dolore magna.\n        </Tooltip>\n      ))}\n    </Row>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      mattressIpsum,
      Tooltip,
      Button,
      Select,
      QuestionMark,
      Row,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const positions = ['right', 'left', 'top', 'top-left', 'top-right', 'bottom-right', 'bottom', 'bottom-left'];
        return <Row mdxType="Row">
        {positions.map(position => <Tooltip key={position} position={position} trigger={<Button size="sm" mdxType="Button">{position}</Button>} mdxType="Tooltip">
            {position.toUpperCase()} Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna.
          </Tooltip>)}
      </Row>;
      }}
    </Playground>
    <h2 {...{
      "id": "controlled-state"
    }}>{`Controlled State`}</h2>
    <Playground __position={5} __code={'<Component\n  initialState={{\n    isOpen: false,\n  }}\n>\n  {({ setState, state }) => {\n    return (\n      <>\n        <Button onClick={() => setState({ isOpen: !state.isOpen })}>\n          {state.isOpen ? \'Close\' : \'Open\'} it\n        </Button>\n        <div style={{ textAlign: \'center\' }}>\n          <Tooltip\n            trigger={<p>Open my tooltip by clicking on the button</p>}\n            isOpen={state.isOpen}\n            onClose={() => setState({ isOpen: false })}\n            onOpen={() => setState({ isOpen: true })}\n          >\n            <div>I am a tooltip</div>\n            <Button onClick={() => setState({ isOpen: !state.isOpen })}>\n              Close me\n            </Button>\n          </Tooltip>\n        </div>\n      </>\n    )\n  }}\n</Component>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      mattressIpsum,
      Tooltip,
      Button,
      Select,
      QuestionMark,
      Row,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Component initialState={{
        isOpen: false
      }} mdxType="Component">
    {({
          setState,
          state
        }) => {
          return <>
          <Button onClick={() => setState({
              isOpen: !state.isOpen
            })} mdxType="Button">{state.isOpen ? 'Close' : 'Open'} it</Button>
          <div style={{
              textAlign: 'center'
            }}>
            <Tooltip trigger={<p>Open my tooltip by clicking on the button</p>} isOpen={state.isOpen} onClose={() => setState({
                isOpen: false
              })} onOpen={() => setState({
                isOpen: true
              })} mdxType="Tooltip">
              <div>I am a tooltip</div>
              <Button onClick={() => setState({
                  isOpen: !state.isOpen
                })} mdxType="Button">Close me</Button>
            </Tooltip>
          </div>
        </>;
        }}
  </Component>
    </Playground>
    <h2 {...{
      "id": "animations"
    }}>{`Animations`}</h2>
    <Playground __position={6} __code={'<Component\n  initialState={{\n    delay: 0,\n    duration: 180,\n    easing: \'ease-in-out\',\n  }}\n>\n  {({ setState, state }) => {\n    const marginRight = { marginRight: 8 }\n    return (\n      <>\n        <Row>\n          <div>\n            <label style={marginRight}>Delay</label>\n            <input\n              placeholder=\"Delay\"\n              name=\"delay\"\n              type=\"number\"\n              onChange={e => setState({ delay: +e.target.value })}\n              value={state.delay}\n            />\n          </div>\n          <div>\n            <label style={marginRight}>Duration</label>\n            <input\n              placeholder=\"Duration\"\n              name=\"duration\"\n              type=\"number\"\n              onChange={e => setState({ duration: +e.target.value })}\n              value={state.duration}\n            />\n          </div>\n          <Select\n            label=\"Easing\"\n            placeholder=\"Easing\"\n            onChange={e => setState({ easing: e.target.value })}\n            value={state.easing}\n          >\n            <option value=\"ease\">ease</option>\n            <option value=\"easing-in\">easing-in</option>\n            <option value=\"easing-out\">easing-out</option>\n            <option value=\"easing-in-out\">easing-in-out</option>\n            <option value=\"linear\">linear</option>\n            <option value=\"step-start\">step-start</option>\n            <option value=\"step-end\">step-end</option>\n          </Select>\n        </Row>\n        <div style={{ marginTop: \'100px\', textAlign: \'center\' }}>\n          <Tooltip\n            trigger={<Button size=\"sm\">Click Me</Button>}\n            delay={state.delay}\n            duration={state.duration}\n            easing={state.easing}\n          >\n            Customize me by changing the inputs above\n          </Tooltip>\n        </div>\n      </>\n    )\n  }}\n</Component>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      mattressIpsum,
      Tooltip,
      Button,
      Select,
      QuestionMark,
      Row,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Component initialState={{
        delay: 0,
        duration: 180,
        easing: 'ease-in-out'
      }} mdxType="Component">
    {({
          setState,
          state
        }) => {
          const marginRight = {
            marginRight: 8
          };
          return <>
          <Row mdxType="Row">
            <div>
              <label style={marginRight}>Delay</label>
              <input placeholder="Delay" name="delay" type="number" onChange={e => setState({
                  delay: +e.target.value
                })} value={state.delay} />
            </div>
            <div>
              <label style={marginRight}>Duration</label>
              <input placeholder="Duration" name="duration" type="number" onChange={e => setState({
                  duration: +e.target.value
                })} value={state.duration} />
            </div>
            <Select label="Easing" placeholder="Easing" onChange={e => setState({
                easing: e.target.value
              })} value={state.easing} mdxType="Select">
              <option value="ease">ease</option>
              <option value="easing-in">easing-in</option>
              <option value="easing-out">easing-out</option>
              <option value="easing-in-out">easing-in-out</option>
              <option value="linear">linear</option>
              <option value="step-start">step-start</option>
              <option value="step-end">step-end</option>
            </Select>
          </Row>
          <div style={{
              marginTop: '100px',
              textAlign: 'center'
            }}>
            <Tooltip trigger={<Button size="sm" mdxType="Button">Click Me</Button>} delay={state.delay} duration={state.duration} easing={state.easing} mdxType="Tooltip">
              Customize me by changing the inputs above
            </Tooltip>
          </div>
        </>;
        }}
  </Component>
    </Playground>
    <h2 {...{
      "id": "hover"
    }}>{`Hover`}</h2>
    <Playground __position={7} __code={'<Row>\n  <>\n    <Tooltip hover trigger={<QuestionMark />} position=\"bottom\">\n      <div>\n        Hover content with action: <Button size=\"sm\">Click</Button>\n      </div>\n    </Tooltip>\n  </>\n  <>\n    <Tooltip hover trigger={<QuestionMark />} position=\"top\">\n      <div style={{ height: \'300px\' }}>\n        <Button size=\"sm\">Click</Button>\n        <div>Tall content with action</div>\n      </div>\n    </Tooltip>\n  </>\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      mattressIpsum,
      Tooltip,
      Button,
      Select,
      QuestionMark,
      Row,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <>
      <Tooltip hover trigger={<QuestionMark mdxType="QuestionMark" />} position="bottom" mdxType="Tooltip">
        <div>
          Hover content with action: <Button size="sm" mdxType="Button">Click</Button>
        </div>
      </Tooltip>
    </>
    <>
      <Tooltip hover trigger={<QuestionMark mdxType="QuestionMark" />} position="top" mdxType="Tooltip">
        <div style={{
              height: '300px'
            }}>
          <Button size="sm" mdxType="Button">Click</Button>
          <div>Tall content with action</div>
        </div>
      </Tooltip>
    </>
  </Row>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      